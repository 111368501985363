import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import playersReducer from './slices/players'
import registrationReducer from './slices/registration';
import productReducer from './slices/product'
import userReducer from './slices/user';
import orgReducer from './slices/org';
import cartReducer from './slices/cart';
import eventReducer from './slices/event';
import invoiceReducer from './slices/invoice';
import orgConfigReducer from './slices/orgConfig';
import assessmentSlice from './slices/assessment';
import pageDataSlice from './slices/page';
import eventDetailsSlice from './slices/eventDetails';
// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: [],
};

const productPersistConfig = {
  key: 'product',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['sortBy', 'checkout'],
};

const rootReducer = combineReducers({
  players: playersReducer,
  registration: registrationReducer,
  product: productReducer,
  user: userReducer,
  org: orgReducer,
  cart: cartReducer,
  invoice: invoiceReducer,
  orgConfig: orgConfigReducer,
  event: eventReducer,
  assessment: assessmentSlice,
  pageData: pageDataSlice,
  eventDetails: eventDetailsSlice
});

export { rootPersistConfig, rootReducer };
