import React from 'react';
import { Box, Card, Typography, Stack, Avatar } from '@mui/material';
import DynamicAspectRatioImage from '../../../components/DynamicAspectRatioImage';
import Iconify from '../../../components/Iconify';
import useResponsive from '../../../hooks/useResponsive';
import { fCurrency } from '../../../utils/formatNumber';

const EventPrice = ({ eventPrice }) => {
  const mdUp = useResponsive('up', 'md');
return(
    <Box sx={{ p: 2, bgcolor: 'white', m: {md:1}  }}
    >
      <Stack direction="row" spacing={1}>
        <Box
          sx={{
            display: 'flex',
                  alignItems: 'flex-start',
                  pt: 0.5,
                }}
              >
                <Iconify
                  icon={eventPrice.icon}
                  sx={{ color: 'black', width: 24, height: 24 }} />
              </Box>
              <Box>
              <Typography variant="h6" color="text.primary">
              {eventPrice.label}
                </Typography>
                <Typography  variant={mdUp ? "body1" : "body2"} fontWeight={400} color={'text.secondary'}>
                  {fCurrency(eventPrice.value)}
                </Typography>
              </Box>
            </Stack>
    </Box>)
}
export default EventPrice;