import { useState, useCallback, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Switch from '@mui/material/Switch';
import Dialog from '@mui/material/Dialog';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import Typography from '@mui/material/Typography';
import FormControl from '@mui/material/FormControl';
import DialogTitle from '@mui/material/DialogTitle';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Stack, TextField } from '@mui/material';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { parsePhoneNumber } from 'react-phone-number-input';

import { useBoolean } from '../../hooks/use-boolean';
import AddEventRegistrationStepper from './AddEventRegistrationStepper';
import { setActiveStepTournamentRegistration } from '../../redux/slices/tournamentRegistrationSlice';
import AddEventForRegistration from './AddEventForRegistration';
import TEventDocUpload from './TEventDocUpload';
import PlayerTeamList from './PlayerTeamList';
import { PhoneInput } from '../../components/phone-input';


// ----------------------------------------------------------------------

const AddEventRegistrationDialog = () => {
  const dialog = useBoolean();
  const [maxWidth, setMaxWidth] = useState('sm');
  const dispatch = useDispatch();
  const { stepper: { activeStep, STEPS } } = useSelector((state) => state.tournamentRegistration);
  const [mobileWithCode, setMobileWithCode] = useState('');
  const [mobile, setMobile] = useState("");
  const [dialCode, setDialCode] = useState("91");

  useEffect(() => {
    if (mobileWithCode) {
      const phoneNumber = parsePhoneNumber(mobileWithCode);
      if (phoneNumber) {
        setMobile(phoneNumber.nationalNumber);
        setDialCode(phoneNumber.countryCallingCode);
      }
    } else {
      setMobile('');
    }
  }, [mobileWithCode]);


  const handleNext = () => {
    dispatch(setActiveStepTournamentRegistration(activeStep + 1));

  };

  const handleBack = () => {
    dispatch(setActiveStepTournamentRegistration(activeStep - 1));
  };

  const handleReset = () => {
    dispatch(setActiveStepTournamentRegistration(0));
  };
  const getStepContent = (step) => {
    switch (step) {
      case 0:
        return <AddEventForRegistration />;
      case 1:
        return <PlayerTeamList />;
      case 2:
        return <TEventDocUpload />;
      default:
        return 'Unknown step';
    }
  }

  const handlemobileChange = (e) => {
    setMobileWithCode(e);
  };

  return (
    <>
      <Button variant="outlined" onClick={dialog.onTrue}>
        Max Width Dialog
      </Button>
      <Stack p={4}> <PhoneInput name={"mobileNumber"} label={"Mobile number"} onChange={handlemobileChange} />

        <DatePicker label={"Date of birth"} format="DD/MMM/YYYY" />
        <TextField
          required
          onChange={(e) => {

          }}
          placeholder='Player First Name'
          label={'Player First Name'}
        />
        <TextField
          required
          onChange={(e) => {

          }}
          label={'Player Last Name'}
          placeholder='Player Last Name'
        />
        <PhoneInput name={"alternateMobileNumber"} label={"Alternate Mobile number"}
          onChange={handlemobileChange}
        />
      </Stack>
      <Dialog
        open={dialog.value}
        fullScreen
        maxWidth={maxWidth}
        onClose={dialog.onFalse}
      >
        <DialogTitle> <AddEventRegistrationStepper /></DialogTitle>

        <DialogContent>
          <Box

            pt={2}
            sx={{
              display: 'flex',
              flexDirection: 'column',
            }}
          >
            {getStepContent(activeStep)}

          </Box>
        </DialogContent>

        <DialogActions>
          <Box sx={{ textAlign: 'right' }}>
            <Button disabled={activeStep === 0} onClick={handleBack} sx={{ mr: 1 }}>
              Back
            </Button>
            <Button variant="contained" onClick={handleNext} sx={{ mr: 1 }}>
              {activeStep === STEPS.length - 1 ? 'Finish' : 'Next'}
            </Button>
            {/* <Button color="inherit" onClick={handleReset} sx={{ mr: 1 }}>
              Reset
            </Button> */}
          </Box>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AddEventRegistrationDialog;
