import { useState, useEffect, useCallback, useRef } from 'react';
import { Box, Typography, Card, CardContent, Button, Stack, List, Chip } from '@mui/material';
import { useSelector } from 'react-redux';
import PersonAddIcon from '@mui/icons-material/PersonAdd';
import EditIcon from '@mui/icons-material/Edit';
import CheckCircleOutlineIcon from '@mui/icons-material/CheckCircleOutline';
import GroupsIcon from '@mui/icons-material/Groups';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { fCurrency } from '../../../utils/formatNumber';
import PartnerDialog from './PartnerDialog';
import LoadingScreen from '../../../components/LoadingScreen';
import useResponsive from '../../../hooks/useResponsive';
import Scrollbar from '../../../components/Scrollbar';

const TournamentRegister = ({ setActiveStep, onEventSelect }) => {
  const mdUp = useResponsive('up', 'md');
  const { eventDetailsData, eventDetailsLoader } = useSelector((state) => state.eventDetails);
  const [selectedEvents, setSelectedEvents] = useState([]);
  const [openPartnerDialog, setOpenPartnerDialog] = useState(false);
  const [currentEvent, setCurrentEvent] = useState(null);
  const [partnerDetails, setPartnerDetails] = useState({});
  const [savedPartners, setSavedPartners] = useState([]);
  const { enqueueSnackbar } = useSnackbar();


  useEffect(() => {
    const savedPartnersData = localStorage.getItem('savedPartners');
    if (savedPartnersData) {
      setSavedPartners(JSON.parse(savedPartnersData));
    }
    const tournamentData = JSON.parse(localStorage.getItem('tournamentData'));
    if (tournamentData) {
      setSelectedEvents(tournamentData.selectedEvents || []);
      setPartnerDetails(tournamentData.partnerDetails || {});
    }
  }, []);

  useEffect(() => {
    const existingData = JSON.parse(localStorage.getItem('tournamentData') || '{}');
    
    localStorage.setItem(
      'tournamentData',
      JSON.stringify({
        ...existingData,  
        selectedEvents,
        partnerDetails,
      })
    );
  }, [selectedEvents, partnerDetails]);

  const handleSelect = useCallback(
    (event) => {
      if (selectedEvents.includes(event.id)) {
        setSelectedEvents(selectedEvents.filter((id) => id !== event.id));
        
        setPartnerDetails(prev => {
          const newPartnerDetails = { ...prev };
          delete newPartnerDetails[event.id];
          return newPartnerDetails;
        });
      } else {
        if (selectedEvents.length >= eventDetailsData?.config?.maxSubEventRegistraionAllowed) {
          enqueueSnackbar(`You can only select up to ${eventDetailsData?.config?.maxSubEventRegistraionAllowed} events`, {
            variant: 'error',
            autoHideDuration: 3000,
          });
          return;
        }

        if (event.isTeamEvent || event.type === 'team_of_two') {
          setCurrentEvent(event);
          setOpenPartnerDialog(true);
        } else {
          setSelectedEvents([...selectedEvents, event.id]);
        }
      }
    },
    [selectedEvents]
  );

  const handleNext = () => {
    const selectedSubEvents = eventDetailsData?.sub_events
      ?.filter((event) => selectedEvents.includes(event.id))
      ?.map((event) => ({
        id: event.id,
        name: event.name,
        type: event.type,
        price: event.priceConfig?.price,
      }));

    onEventSelect({
      eventId: eventDetailsData?.id,
      selectedEvents: selectedSubEvents,
      partnerDetails,
    });
    setActiveStep(2);
  };

  const handlePartnerSubmit = (data) => {
    if (currentEvent) {
        // Update partner details
        if (data.id) {
            setPartnerDetails(prev => ({
                ...prev,
                [currentEvent.id]: data
            }));
        } else {
            let formattedDate = null;
            if (data.dob instanceof Date) {
                formattedDate = moment(data.dob).format('YYYY-MM-DD');
            } else if (data.dob) {
                formattedDate = moment(new Date(data.dob)).format('YYYY-MM-DD');
            }

            const formattedData = {
                ...data,
                dob: formattedDate,
                name: data.name || '',
                email: data.email || '',
                gender: data.gender || '',
                mobile: data.mobile || ''
            };
        
            // Save new partner if doesn't exist
            const existingPartner = savedPartners.find(p => p.mobile === formattedData.mobile);
            if (!existingPartner) {
                const updatedPartners = [...savedPartners, formattedData];
                localStorage.setItem('savedPartners', JSON.stringify(updatedPartners));
                setSavedPartners(updatedPartners);
            }

            setPartnerDetails(prev => ({
                ...prev,
                [currentEvent.id]: formattedData
            }));
        }

        // Only add to selectedEvents if it's not already there
        if (!selectedEvents.includes(currentEvent.id)) {
            setSelectedEvents(prev => [...prev, currentEvent.id]);
        }

        setOpenPartnerDialog(false);
        setCurrentEvent(null);
    }
  };

  const handleAddPartner = useCallback((event) => {
    setCurrentEvent(event);
    setOpenPartnerDialog(true);
  }, []);

  if (eventDetailsLoader === 'loading') {
    return <LoadingScreen />;
  }

  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column',
      height: '100%',
    }}>
      <Box 
        sx={{ 
          overflowY: 'auto', 
          position: 'relative',
          p:1,
          backgroundColor: 'background.neutral',
          borderRadius: 2
        }}
      >
        <Scrollbar
          sx={{ height: 1 }}
          slotProps={{
            content: { height: 1, display: 'flex', alignItems: 'center' },
          }}
        >
          <List sx={{ width: '100%' }}>
            {eventDetailsData?.sub_events?.map((event) => (
          <Card key={event.id} sx={{ mb: 2 }}>
            <CardContent>
              <Stack spacing={0}>
                <Stack direction="row" alignItems="center" spacing={1}>
                  <GroupsIcon color="action" sx={{ fontSize: '1.2rem' }} />
                  <Typography variant="h6">{event.name}</Typography>
                  {event.isTeamEvent ? (
                    <Chip
                      label="Doubles"
                      size="small"
                      sx={{
                        ml: 1,
                      }} />
                  ) : null}
                </Stack>

                {event.isTeamEvent
                  ? selectedEvents.includes(event.id) && (
                    <Stack spacing={0} sx={{ mt: 0 }}>
                      <Typography variant="body2" color="text.secondary" sx={{ mb: 0 }}>
                        Partner
                      </Typography>
                      <Stack direction="row" justifyContent="space-between" alignItems="center">
                        <Typography variant="h6">{partnerDetails[event.id]?.name || partnerDetails[event.id]?.firstName || 'Add your partner'}</Typography>
                        <Button
                          startIcon={partnerDetails[event.id] ? <EditIcon /> : <PersonAddIcon />}
                          onClick={(e) => {
                            e.stopPropagation();
                            handleAddPartner(event);
                          } }
                        >
                          {partnerDetails[event.id] ? 'Edit Partner' : 'Add Partner'}
                        </Button>
                      </Stack>
                    </Stack>
                  )
                  : null}

                <>
                  <Typography variant="subtitle2" color="text.secondary">
                    Registration Fee
                  </Typography>
                  <Typography variant="h6">{fCurrency(event.priceConfig?.price)}</Typography>
                </>

                <Button
                  startIcon={selectedEvents.includes(event.id) ? <CheckCircleOutlineIcon /> : null}
                  variant={selectedEvents.includes(event.id) ? 'contained' : 'outlined'}
                  onClick={(e) => {
                    e.stopPropagation();
                    handleSelect(event);
                  } }
                  fullWidth
                  sx={{ mt: 1 }}
                >
                  {selectedEvents.includes(event.id) ? 'Selected' : 'Select'}
                </Button>
              </Stack>
            </CardContent>
          </Card>
        ))}
          </List>
        </Scrollbar>
      </Box>
      <PartnerDialog
        open={openPartnerDialog}
        onClose={() => {
          setOpenPartnerDialog(false);
        } }
        onSubmit={handlePartnerSubmit}
        currentEvent={currentEvent}
        savedPartners={savedPartners}
        existingPartner={currentEvent ? partnerDetails[currentEvent.id] : null} />
      <Box sx={{ mt: 3 }}>
        <Stack direction="row" justifyContent="space-between">
          <Button variant="outlined" color="primary" onClick={() => setActiveStep(0)}>
            Back
          </Button>
          <Button variant="contained" color="primary" disabled={selectedEvents.length === 0} onClick={handleNext}>
            Next
          </Button>
        </Stack>
      </Box>
    </Box>
  );
};

export default TournamentRegister;
