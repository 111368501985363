import React, { forwardRef } from 'react'
import {
  Box,
  Grid,
  Card,
  Typography,
  Stack,
  Avatar,
} from '@mui/material';
import { fCurrency } from '../../../utils/formatNumber';
import Iconify from '../../../components/Iconify';
import ContactDetails from './ContactDetails';
import Organiser from './Organiser';
import Location from './Location'
import EventDate from './EventDate';
import EventTime from './EventTime';
import EventPrice from './EventPrice';
import useResponsive from '../../../hooks/useResponsive';

// Helper function to determine the appropriate icon based on the heading
const getIcon = (heading) => {
  switch (heading) {
    case 'Time':
      return 'solar:clock-circle-bold';
    case 'Location':
      return 'logos:google-maps';
    default:
      return 'solar:calendar-bold';
  }
};
const About = forwardRef(({ about }, ref) => {
  const mdUp = useResponsive('up', 'md');
 
  return (
    <Box sx={{ display: 'flex' }}>
      <Card
        ref={ref}
        sx={{
          mx: { xs: 0, md: 4 },
          px: { xs:1,md: 4 },
          py: { xs: 1, md: 3 },
          bgcolor: 'white',
          mb: { xs: 3 },
          mt: { md: 3 },
          width: { xs: '100%', md: '100%' }, 
        }}
      >
        <Grid container >
          <Grid item xs={12} md={8}>
            <Box display={'flex'} flexDirection={mdUp ? 'row' : 'column'} >
              <EventDate eventDates={about?.data?.date}  />
              <EventTime eventTime={about?.data?.time} />
              {about?.data?.price && <EventPrice eventPrice={(about?.data?.price)} />}
              </Box>
            <Location locationData={about?.data?.location}/>
          </Grid>
          <Grid item xs={12} md={4} mt={2}>
            <Stack spacing={2}>
              <ContactDetails contactData={about?.data} />
              <Organiser organiser={about?.data}/>
            </Stack>
          </Grid>
        </Grid>
      </Card>
    </Box>
  );
});

export default About;