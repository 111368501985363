import React from 'react';
import {
  Box,
  Typography,
} from '@mui/material';

import useResponsive from '../../../hooks/useResponsive';

import Iconify from '../../../components/Iconify';



const NoticeBanner = ({ message }) => {
    const mdUp = useResponsive('up', 'md');
    return (
    <Box 
        sx={{ 
            bgcolor: 'info.lighter', 
          p:2,
          m:{md: 4, xs:0},
          my:{md: 3, xs:2},
            borderRadius: 1,
            display: 'flex',
            gap: 1,
            textAlign: 'left'
     
        }}
    >
        <Iconify 
            icon="mdi:information" 
            sx={{ 
                color: 'info.main',
                width: 24,
                height: 24,
                flexShrink: 0
            }} 
        />
        <Typography variant={mdUp ? "subtitle2" : "subtitle2"} color="info.dark">
            {message}
        </Typography>
    </Box>
    );
};

export default NoticeBanner;