import React from 'react'
import { m } from 'framer-motion';
import { Modal, TextField, Button, Typography, Box, Stack, Backdrop, CircularProgress } from "@mui/material";
import { varFade } from '../../components/animate'
import Image from '../../components/Image';
import KRIDA_LOGO from "../../assets/logo_KRIDA.png"
import useResponsive from '../../hooks/useResponsive';
import Logo from '../../components/Logo';

const KridaPowered = ({ size, color = '#193052' }) => {
    const smDown = useResponsive('down', 'sm');
    return (
        <Box sx={{
            width: '100%',
            display: "flex",
            justifyContent: "center"
        }}>
            <m.div style={{
                display: 'flex',
                justifyContent: 'flex-end',
                flexDirection: 'row',
                textAlign: 'end',
            }} variants={varFade().inRight}>
                <Stack direction={'row'} justifyContent={'flex-end'} alignItems='center' width={'100%'}>

                    <Stack direction={'row'} alignItems={'center'}>
                        <Logo />
                        <Stack direction={'column'} alignItems='flex-start' pr={2} pl={1} justifyContent={'flex-start'}>
                            <Typography variant="caption" sx={{ color }}>
                                powered by
                            </Typography>
                            <Typography variant="h3" sx={{ color, lineHeight: 1 }}>
                                Krida
                            </Typography>
                        </Stack>
                    </Stack>
                </Stack>
            </m.div>
        </Box>
    )
}
export default KridaPowered