import React from 'react';
import { Box, Button, Typography, Stack, Link, CircularProgress } from '@mui/material';
import { useSelector, useDispatch } from 'react-redux';
import { isValidPhoneNumber, parsePhoneNumber } from 'react-phone-number-input';
import { useSnackbar } from 'notistack';

import { PhoneInput } from '../../phone-input';
import useLoginFormState from '../hooks/useLoginFormState';


const LoginStep = () => {
    const { mobileWithCode, isLoading } = useSelector((state) => state.user);
    const { actions: { updateField, setActiveStep, handleLogin } } = useLoginFormState();
    const handleInputChange = (value) => {
        updateField('mobileWithCode', value || '' )
    };

    const handleSignUpRedirect = () => {
        setActiveStep(2);
    };

    return (
        <Box sx={{ px: 2, py: 4 }}>
            <PhoneInput
                label="Mobile Number"
                value={mobileWithCode}
                onChange={handleInputChange}
                fullWidth
                variant="outlined"
                sx={{ mb: 2 }}
                inputProps={{
                    autoFocus: true,
                }}
            />
            <Button
                fullWidth
                variant="contained"
                color="primary"
                onClick={handleLogin}
                disabled={!isValidPhoneNumber(mobileWithCode) || isLoading}
            >
                {isLoading ? <CircularProgress size={24} color="inherit" /> : 'Continue'}
            </Button>
            <Stack direction="row" justifyContent="center" alignItems="center" sx={{ mt: 2 }}>
                <Typography variant="subtitle2">Don't have an account?</Typography>
                <Link
                    component="button"
                    variant="subtitle2"
                    underline="none"
                    onClick={handleSignUpRedirect}
                    sx={{ ml: 1 }}
                >
                    Sign Up
                </Link>
            </Stack>
        </Box>
    );
};

export default LoginStep;
