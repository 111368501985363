import { 
    Box, 
    Card, 
    Avatar, 
    Divider, 
    Typography 
  } from '@mui/material';

  const UserCard = ({ user }) => (
        <Card sx={{ 
          textAlign: 'center', 
          boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
        }}>
          <Box sx={{display: 'flex', justifyContent: 'start', alignItems: 'center'}}>     
           <Avatar
              src={user.firstName}
              alt={user.firstName}
              sx={{
                m: 2,
                mb:1,
                width: 50,
                height: 50,
                border: '4px solid',
                borderColor: 'background.paper',
                boxShadow: '0 2px 4px rgba(0,0,0,0.1)'
              }}
            />
  
            {/* User Name */}
            <Typography variant="h6" >
              {user.firstName} {user.lastName}
            </Typography>
           </Box>
  
          <Divider sx={{ my:1, borderStyle: 'dashed' }} />
  
          {/* Contact Details */}
          <Box
            sx={{
              display: 'grid',
              gap: 2,
              gridTemplateColumns: 'repeat(2, 1fr)',
              mb: 2
            }}
          >
            <Box>
              <Typography 
                variant="caption" 
                component="div" 
                sx={{ 
                  mb: 0.5, 
                  color: 'text.secondary' 
                }}
              >
                Date Of Birth
              </Typography>
              <Typography 
                variant="body2" 
                sx={{ 
                  wordBreak: 'break-word' 
                }}
              >
                {user.dob}
              </Typography>
            </Box>
  
            <Box>
              <Typography 
                variant="caption" 
                component="div" 
                sx={{ 
                  mb: 0.5, 
                  color: 'text.secondary' 
                }}
              >
                Gender
              </Typography>
              <Typography variant="body2">
                  {user.gender}
              </Typography>
            </Box>
          </Box>
        </Card>
    );
  
  export default UserCard;