import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import { KRIDA_API } from '../../config';
import axios from '../../utils/axios'

const initialState = {
    stepper: {
        activeStep: 0,
        STEPS: ['Select event', 'Add partner', 'Documents'],
    },
}
const tournamentRegistrationSlice = createSlice({
    name: 'tournamentRegistration',
    initialState,
    reducers: {
        setActiveStepTournamentRegistration: (state, action) => {
            state.stepper.activeStep = action.payload
        }

    },
    extraReducers: (builder) => {

    }
})




export const { setActiveStepTournamentRegistration } = tournamentRegistrationSlice.actions

export default tournamentRegistrationSlice.reducer;