import React, { useState, useEffect } from 'react';
import { Box, Typography, Stepper, Step, StepLabel, Container, Stack } from '@mui/material';

import { useParams } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import MainHeader from '../../../layouts/main/MainHeader';
import Image from '../../../components/Image';
import BOOK_FREE_TRIAL_BACKGROUND from '../../../assets/Event.svg';

import BasicDetails from './BasicDetails';
import TournamentRegister from './TournamentRegister';
import VerifyForm from './VerifyForm';
import Success from './Success';
import { fetchEventDetail } from '../../../redux/slices/eventDetails';
import Page404 from '../../Page404';
import useResponsive from '../../../hooks/useResponsive';
import { setLoginFormOpen } from '../../../redux/slices/user';
import LoadingScreen from '../../../components/LoadingScreen';


const steps = ['Registration', 'Select Events', 'Verify'];

const RegisterEvent = () => {
  const { tId } = useParams();
  const dispatch = useDispatch();
  const { eventDetailsData, eventDetailsLoader, error } = useSelector((state) => state.eventDetails);
  const { currentPlayer,loader } = useSelector((state) => state.user);
  const [activeStep, setActiveStep] = useState(0);
  const [isInvalid, setIsInvalid] = useState(false);
  const [tournamentData, setTournamentData] = useState({
    selectedEvents: [],
    events: [],
    partnerDetails: {},
  });
  const mdUp = useResponsive('up', 'md');

  useEffect(() => {
    if (!currentPlayer?.id) {
      dispatch(setLoginFormOpen(true));
    }
  }, [currentPlayer?.id, dispatch]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        await dispatch(fetchEventDetail(tId)).unwrap();
      } catch (error) {
        console.error('Failed to fetch event details:', error);
        setIsInvalid(true);
      }
    };

    fetchData();
  }, [dispatch, tId]);

  const handleEventSelect = (data) => {
    setTournamentData(data);
  };
  if (
    isInvalid || 
    (error && eventDetailsLoader === 'failed') ||
    (!eventDetailsData?.id && eventDetailsLoader === 'succeeded')
  ) {
    return <Page404 />;
  }

  if(loader === true){
    return <LoadingScreen />;
  }
  const renderStepContent = (step) => {
    switch (step) {
      case 0:
        return <BasicDetails setActiveStep={setActiveStep} />;
      case 1:
        return <TournamentRegister setActiveStep={setActiveStep} onEventSelect={handleEventSelect} />;
      case 2:
        return (
          <VerifyForm
            setActiveStep={setActiveStep}
            events={tournamentData.events}
            eventId={eventDetailsData?.id}
          />
        );
      default:
        return null;
    }
  };

  return (
    <>
      <MainHeader />
      <Container
        maxWidth="lg"
        sx={{
          pt: { xs: 6, md: 10 },
          px: 2,
          minHeight: '100vh',
        }}
      >
        <Stack direction={{ xs: 'column', md: 'row' }} spacing={{ xs: 4, md: 8 }} >
          <Box flex={1} sx={{ display: { xs: 'none', md: 'flex' }, alignItems: 'start' }}>
            <Image src={BOOK_FREE_TRIAL_BACKGROUND} alt="background" />
          </Box>

          <Stack flex={1} spacing={3} minHeight='100%'>
            <Stack spacing={{ xs: 1, md: 2 }}>
              <Typography variant={mdUp ? 'h4' : 'h5'}>Registration for {eventDetailsData?.name}</Typography>
            </Stack>

            <Stepper activeStep={activeStep} alternativeLabel>
              {steps.map((label) => (
                <Step key={label}>
                  <StepLabel>{label}</StepLabel>
                </Step>
              ))}
            </Stepper>

            {!currentPlayer?.id ? (
              <Box
                sx={{
                  mt: '200px',
                  maxHeight: 'calc(100vh - 350px)',
                  display: 'flex',
                  flexDirection: 'column',
                  alignItems: 'center',
                  justifyContent: 'center',
                  gap: 2,
                  pb: 4,
                }}
              >
                <Typography
                  variant="h4"
                  sx={{
                    fontWeight: 600,
                    color: 'primary.main',
                    textAlign: 'center',
                    mb: 2,
                  }}
                >
                  Login to Continue
                </Typography>
                <Box sx={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap:0.5 }}>
                  <Typography
                    variant="body1"
                    sx={{
                      color: 'text.secondary',
                      textAlign: 'center',
                    }}
                  >
                    Please{' '}
                  </Typography>
                  <Typography
                    onClick={() => dispatch(setLoginFormOpen(true))}
                    sx={{
                      color: 'primary.main',
                      cursor: 'pointer',
                      textDecoration: 'underline',
                    }}
                  >
                    Login
                  </Typography>
                  <Typography variant="body1"> to proceed with the registration</Typography>
                </Box>
              </Box>
            ) : (
              <Box
                sx={{
                  height: 'calc(100vh - 250px)',
                }}
              >
                {renderStepContent(activeStep)}
               </Box>
            )}
          </Stack>
        </Stack>
      </Container>
    </>
  );
};

export default RegisterEvent;
