import React from 'react';
import { Box, Button, Typography, Stack } from '@mui/material';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useNavigate } from 'react-router-dom';
import { registerEvent } from '../../../redux/slices/eventDetails';
import useResponsive from '../../../hooks/useResponsive';
import { fCurrency } from '../../../utils/formatNumber';

const VerifyForm = ({ setActiveStep, eventId, isEvent,price }) => {
  const { enqueueSnackbar } = useSnackbar();
  const navigate = useNavigate();
  const { currentPlayer } = useSelector((state) => state.user);
  const { eventDetailsData } = useSelector((state) => state.eventDetails);
  const dispatch = useDispatch();

  const tournamentData = JSON.parse(localStorage.getItem('tournamentData') || '{}');
  const { selectedEvents: selectedEventIds = [], partnerDetails = {}, academyNameAndPlace = '', ccpBeginnerSerialNumber = '' } = tournamentData;

  const selectedEvents = isEvent ? eventId :selectedEventIds
    .map(eventId => eventDetailsData?.sub_events?.find(event => event.id === eventId))

  const calculateTotalAmount = () => selectedEvents.reduce((total, event) => {
      const amount =  Number(event.priceConfig?.price || 0);
      return total + amount;
    }, 0);

  const handleProceedToPayment = async () => {
    if (currentPlayer && selectedEvents.length) {
      const registerData = {
        payload: {
          eventId,
          subEvents: isEvent ? 
          [{
            id: eventId === 'psm-ccp' ? 'psm-ccp-dec' : 'psm-advanced-camp-dec',
            players: [{
              id: currentPlayer.id,
              ...(eventId === 'psm-ccp' && {
                formData: {
                  ccpBeginnerSerialNumber
                }
              }),
            }]
          }] : 
          selectedEvents.map((event) => ({
            id: event.id,
            players: event.isTeamEvent ? [
                {
                    id: currentPlayer.id,
                    formData: {
                        academyNameAndPlace
                    }
                },
                partnerDetails[event.id] && {
                    ...(partnerDetails[event.id]?.id ? { id: partnerDetails[event.id].id } : partnerDetails[event.id]),
                    formData: {
                        academyNameAndPlace
                    }
                }
            ] : [
                {
                    id: currentPlayer.id,
                    formData: {
                        academyNameAndPlace
                    }
                }
            ]
          })),
          playerId: currentPlayer.id,
        },
        enqueueSnackbar,
      };

      try {
       const response = await dispatch(registerEvent(registerData)).unwrap();
        localStorage.removeItem('tournamentData');
        if(response?.data?.redirectPath){
          navigate(`${response?.data?.redirectPath}`);
        }
      } catch (error) {
        enqueueSnackbar(error?.message || 'Registration failed', { variant: 'error' });
        localStorage.removeItem('tournamentData');
      }
    }
  };

  return (
    <Box sx={{ 
      height: '100%',
      display: 'flex',
      flexDirection: 'column',
    }}>
      {isEvent ? (
        <Box {...(eventId === 'psm-ccp' && {flex: 1})}>
          <Typography variant="h6" gutterBottom>
            {eventId === 'psm-ccp' ? 'CCP Beginner Program' : 'Advanced Camp'}
          </Typography>
          {eventId === 'psm-ccp' && ccpBeginnerSerialNumber && (
            <Typography variant="body1" color="text.secondary" sx={{ mt: 1 }}>
              Serial Number: {ccpBeginnerSerialNumber}
            </Typography>
          )}
        </Box>
      ) : (
        <Box flex={1} overflow="auto">
          <Typography variant="h6" gutterBottom>Selected Event(s)</Typography>
          <Box sx={{ mb: 3 }}>
            {selectedEvents?.map((event) => {
              const hasPartner = partnerDetails[event.id];

              return (
                <Stack
                  key={event.id}
                  direction="row"
                  justifyContent="space-between"
                  alignItems="center"
                  sx={{
                    py: 2,
                    borderBottom: '1px solid',
                    borderColor: 'divider',
                  }}
                >
                  <Typography>
                    {event.name}
                    {hasPartner && (
                      <Typography color="text.secondary">
                        Partner: {partnerDetails[event.id].firstName || partnerDetails[event.id].name}
                      </Typography>
                    )}
                  </Typography>
                  <Typography>
                    {fCurrency(event.priceConfig?.price || 0)}
                  </Typography>
                </Stack>
              );
            })}
          </Box>
        </Box>
      )}
      <Stack direction={'column'} justifyContent="space-between" alignItems={'flex-start'} mt={3}>
        <Typography variant="h6">Total Amount: {isEvent ? fCurrency(price) : fCurrency(calculateTotalAmount())}</Typography>
        <Box
          direction="row"
          mt={2}
          sx={{
            display: 'flex',
            justifyContent: 'space-between',
            width: '100%',
          }}
        >
          <Button variant="outlined" onClick={() => setActiveStep(isEvent ? 0 : 1)}>
            Back
          </Button>
          <Button variant="contained" color="primary" onClick={handleProceedToPayment}>
            Proceed to Payment
          </Button>
        </Box>
      </Stack>
    </Box>
  );
};

export default VerifyForm;
