import { useState, useEffect } from 'react';
import { Box, Button, TextField, Stack } from '@mui/material';

import { useNavigate } from 'react-router';
import { useSelector } from '../../../redux/store';

import UserCard from './UserCard';

const BasicDetails = ({ setActiveStep, isEvent, eventId }) => {
  const navigate = useNavigate();
  const [academyNameAndPlace, setAcademyNameAndPlace] = useState('');
  const { currentPlayer } = useSelector((state) => state.user);
  const [ccpBeginnerSerialNumber, setCcpBeginnerSerialNumber] = useState('');

  const handleAcademyNameAndPlace = (e) => {
    setAcademyNameAndPlace(e.target.value);
  };
  const handleCcpBeginnerSerialNumber = (e) => {
    setCcpBeginnerSerialNumber(e.target.value);
  };
  useEffect(() => {
    const tournamentData = JSON.parse(localStorage.getItem('tournamentData') || '{}');
    if (tournamentData) {
      setAcademyNameAndPlace(tournamentData.academyNameAndPlace || '');
      setCcpBeginnerSerialNumber(tournamentData.ccpBeginnerSerialNumber || '');
    }
  }, [currentPlayer]);

  const handleNext = () => {
    const tournamentData = JSON.parse(localStorage.getItem('tournamentData') || '{}');
    if (isEvent) {
      if (eventId === 'psm-ccp' && ccpBeginnerSerialNumber) {
        localStorage.setItem('tournamentData', JSON.stringify({
          ...tournamentData,
          ccpBeginnerSerialNumber
        }));
      }
    } else if (academyNameAndPlace) {
      localStorage.setItem('tournamentData', JSON.stringify({
        ...tournamentData,
        academyNameAndPlace
      }));
    }
    setActiveStep(1);
  };

  return (
      <Box
        sx={{
          display: 'flex',
          height: '100%',
          flexDirection: 'column',
          gap:2
        }}
      >
      <UserCard user={currentPlayer} />
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            justifyContent:'space-between'
          }}
        >
        {eventId === 'psm-ccp' ? <TextField
                fullWidth
                label="CCP Beginner Online Serial Number"
                variant="outlined"
                value={ccpBeginnerSerialNumber}
                onChange={handleCcpBeginnerSerialNumber}
              /> : !isEvent && <TextField
              fullWidth
              label="Academy Name and Place"
              variant="outlined"
              value={academyNameAndPlace}
              onChange={handleAcademyNameAndPlace}
              />}
            <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
                <Button variant="outlined" color="primary" onClick={() => navigate(-1)}>
                Back
              </Button>
              <Button 
                variant="contained" 
                color="primary" 
                onClick={handleNext} 
                disabled={
                  (isEvent && eventId === 'psm-ccp' && !ccpBeginnerSerialNumber) ||
                  (!isEvent && !academyNameAndPlace)
                }
              >
                Next
              </Button>
            </Box>
        </Box>
      </Box>
  );
};

export default BasicDetails;
